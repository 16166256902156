import DashboardLayout from "@/pages/layouts/DashboardLayout";

let branchesRoutes = {
  path: '/branches',
  component: DashboardLayout,
  redirect: '/branches/list',
  children: [
    {
      path: 'list',
      name: 'Branches List',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/pages/branches/BranchList'),
      meta: {
        permissionCode: 'branches/index',
      }
    },
    {
      path: 'create',
      name: 'Add Branch',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/pages/branches/BranchForm'),
      meta: {
        permissionCode: 'branches/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Branch',
      component: () => import(/* webpackChunkName: "branches" */  '@/pages/pages/branches/BranchForm'),
      meta: {
        permissionCode: 'branches/update',
      }
    }
  ]
}

export default branchesRoutes;
