import DashboardLayout from "@/pages/layouts/DashboardLayout";

let rolesRoutes = {
  path: '/roles',
  component: DashboardLayout,
  redirect: '/roles/list',
  children: [
    {
      path: 'list',
      name: 'Role List',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/roles/RoleList'),
      meta: {
        permissionCode: 'roles/index',
      }
    },
    {
      path: 'create',
      name: 'Add Role',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/roles/RoleForm'),
      meta: {
        permissionCode: 'roles/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Role',
      component: ()=>import(/* webpackChunkName: "roles" */  '@/pages/roles/RoleForm'),
      meta: {
        permissionCode: 'roles/update',
      }
    }
  ]
}

export default rolesRoutes;
