import DashboardLayout from "@/pages/layouts/DashboardLayout";

let newsletterRoutes = {
  path: '/newsletter',
  component: DashboardLayout,
  redirect: '/newsletter/list',
  children: [
    {
      path: 'list',
      name: 'Newsletter Subscribers List',
      component:()=>import(/* webpackChunkName: "newsletter" */ '@/pages/general/newsletter/NewsletterList'),
      meta: {
        permissionCode: 'newsletter/index',
      }
    },
  ]
}

export default newsletterRoutes;
