<template>
  <div>

    <el-upload
      ref="upload"
      action="#"
      :http-request="doUpload"
      :show-file-list="false"
      accept="image/jpeg,image/gif,image/png"
    >
      <el-button size="small" type="primary">{{ buttonTitle }}</el-button>
    </el-upload>


    <div v-if="value" class="el-upload-list--picture-card">
      <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
        <img alt="" :src="getUrl()" class="el-upload-list__item-thumbnail">
        <span class="el-upload-list__item-actions">
        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview"><i class="el-icon-zoom-in"></i></span>
        <span class="el-upload-list__item-delete" @click="handleRemove"><i class="el-icon-delete"></i></span></span>
      </div>
    </div>


    <el-dialog :visible.sync="dialogVisible">
      <img style="max-width:100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';

export default {

  name: "ImageUploader",

  props: {
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {
      type: String,
    },
    previewWidth: {
      type: String,
      default:"240px"
    },
    previewHeight: {
      type: String,
      default:"auto"
    },
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button
  },


  data() {
    return {
      dialogImageUrl: '',
      disabled: false,
      dialogVisible: false
    };
  },

  methods: {
    doUpload(params) {
      let bodyFormData = new FormData();
      bodyFormData.append('photo', params.file);
      this.axios.post(process.env.VUE_APP_UPLOAD_IMAGE_URL,
        bodyFormData,
        {
          headers: {"Content-Type": "multipart/form-data"},
        }).then(response => {

        this.$emit("input", response.data.data.url);

      }).catch(err => {
        console.log(err.msg)
      })
    },

    handleRemove() {
      this.$emit('input', "");
    },

    handlePictureCardPreview() {
      this.dialogImageUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },

    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
    },

  }

}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}
</style>
