import DashboardLayout from "@/pages/layouts/DashboardLayout";

let permissionsRoutes = {
  path: '/permissions',
  component: DashboardLayout,
  redirect: '/permissions/list',
  children: [
    {
      path: 'list',
      name: 'Permission List',
      component:()=>import(/* webpackChunkName: "permissions" */ '@/pages/permissions/PermissionList'),
      meta: {
        permissionCode: 'permissions/index',
      }
    },
    {
      path: 'create',
      name: 'Add Permission',
      component:()=>import(/* webpackChunkName: "permissions" */ '@/pages/permissions/PermissionForm'),
      meta: {
        permissionCode: 'permissions/create',
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Permission',
      component:()=>import(/* webpackChunkName: "permissions" */ '@/pages/permissions/PermissionForm'),
      meta: {
        permissionCode: 'permissions/update',
      }
    }
  ]
}

export default permissionsRoutes;
