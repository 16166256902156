<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="navbar-minimize">

      </div>
      <a class="navbar-brand">{{ this.$route.name }}</a>

      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-expanded="false"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>


      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <drop-down position="right">
            <i slot="title" class="nc-icon nc-single-02"></i>
            <a href="javascript:void(0)" @click.prevent="$router.push('/update-profile')"  class="dropdown-item">
              <i class="nc-icon nc-single-02"></i>My Profile
            </a>
            <a href="javascript:void(0)"  @click.prevent="$router.push('/change-my-password')" class="dropdown-item">
              <i class="nc-icon nc-key-25"></i>Change Password
            </a>
            <a href="javascript:void(0)" @click.prevent="logout" class="dropdown-item text-danger">
              <i class="nc-icon nc-button-power"></i> Log out
            </a>
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  computed: {
    routeName() {
      const {name} = this.$route
      return this.capitalizeFirstLetter(name)
    }
  },
  data() {
    return {
      activeNotifications: false
    }
  },
  methods: {
    ...mapActions({
      logoutAction: 'auth/logout'
    }),

    logout(){
      this.logoutAction().then(()=>{
         this.$router.push("/login");
      });
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    closeDropDown() {
      this.activeNotifications = false
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize()
    }
  }
}

</script>
<style scoped>
.dropdown-item {
  width: 13rem;
}
</style>
